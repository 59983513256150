import React from "react";
import MainLayout from "../components/MainLayout";

export default function () {
  return (
    <MainLayout title="404">
      2020 Divided by 5 is <h1 style={{ display: "inline" }}>404</h1>, So the
      Whole Year is an Error. <br /> Badum tssss.
    </MainLayout>
  );
}
